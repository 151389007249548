import React, { useEffect, useState } from "react";
import axios from "axios";
import { graphql, Link } from "gatsby";
import Layout from "../../components/layout";
import { Seo } from "../../components/seo";
import * as styles from "./nft.module.scss";

export const Head = () => <Seo title="NFTs" />;

const Home = ({ data }) => {
  const [ files, setFiles ] = useState([]);
  useEffect(() => {
    (async () => {
      const files = await axios
        .get("https://brochain.blob.core.windows.net/christmas-creatures-showcase/files.json")
        .then(response => response.data);
      setFiles(files);
    })();
  }, []);

  return (
    <Layout>
      <h1>NFTs</h1>
      <h2>Christmas creatures</h2>
      <ul className={styles.frames}>
        {files.slice(0, 1).map(file => (
          <li className={styles.frame}>
            <Link to="/nft/christmas-creatures">
              <img
                src={`https://brochain.blob.core.windows.net/christmas-creatures-showcase/${file.filename}`}
                alt={file.filename}
              />
            </Link>
          </li>
        ))}
      </ul>
    </Layout>
  );
};

export default Home;

export const query = graphql`
  query {
    allFile {
      edges {
        node {
          id
          relativePath
          relativeDirectory
          publicURL
        }
      }
    }
  }
`;
